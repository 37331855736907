import { useEffect, useRef, useState } from 'react';
import './App.css';

  const splitPrice = (price) => {
    const priceArray = price.toString().split("").reverse();
    const result = [];
    for (let i = 0; i < priceArray.length; i++) {
      result.push(priceArray[i]);
      if (i % 3 === 2 && i !== priceArray.length - 1) {
        result.push(",");
      }
    }
    return result.reverse().join("");
  };

const API_URI = "https://dashboard-yos5vu3j6q-an.a.run.app/global_form/create";
const ANIMATION_DURATION = 1000;
const FRAME_RATE = 1000 / 60;

const easeOutQuad = (t) => 1 - Math.pow(1 - t, 3);
function App() {
  const [isProgress, setIsProgress] = useState(null); // boolean or null
  const [target, setTarget] = useState(""); // string ( parseint )
  const [company, setCompany] = useState(""); // string
  const [phone1, setPhone1] = useState(""); // string
  const [phone2, setPhone2] = useState(""); // string
  const [phone3, setPhone3] = useState(""); // string
  const [email, setEmail] = useState(""); // string
  const [name, setName] = useState(""); // string
  const [agreement, setAgreement] = useState(false); // boolean
  const [estimation, setEstimation] = useState(0); // animation container
  const [loading, setLoading] = useState(false); // boolean
  const animationRef = useRef(null);
  const handleClick = async () => {
    const estimate = target
      ? isProgress === true
        ? 5000 * target
        : 1000 * target
      : 0;
    if(loading) return;
    if(isProgress === null || target === '' || company === '' || phone1 === '' || phone2 === '' || phone3 === '' || email === '' || name === '') {
      alert("모든 항목을 입력해주세요.");
      return;
    }
    if(!agreement) {
      alert("개인정보처리방침에 동의해주세요.");
      return;
    }

    setLoading(true);
    fetch(API_URI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company_id: 2,
        json: {
          기업명: company,
          연락처: `${phone1}-${phone2}-${phone3}`,
          가격: splitPrice(estimate),
          교육인원: `${target}명`,
          산업안전보건교육: isProgress ? "O" : "X",
          이메일: email,
          "이름/직책": name,
        },
      }),
    })
      .then((res) => {
        if (res.status < 300) {
          alert("접수가 완료되었습니다.");
          setIsProgress(null);
          setTarget("");
          setCompany("");
          setPhone1("");
          setPhone2("");
          setPhone3("");
          setEmail("");
          setName("");
        } else {
          throw new Error("Failure");
        }
      })
      .catch((err) => {
        alert("잠시 후 다시 시도해주세요.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const estimate = target
      ? isProgress === true
        ? 5000 * target
        : 1000 * target
      : 0;
    setEstimation(0);
    if(animationRef.current) {
      clearInterval(animationRef.current);
      animationRef.current = null;
    }
    let frame = 0;
    const totalFrames = Math.round(ANIMATION_DURATION / FRAME_RATE);
    animationRef.current = setInterval(() => {
      frame++;
      const progress = easeOutQuad(frame / totalFrames);
      setEstimation(parseInt(estimate * progress));
      if (frame >= totalFrames) {
        clearInterval(animationRef.current);
      }
    }, FRAME_RATE);
  }, [target, isProgress]);

  return (
    <div className="wrapper">
      <div className="content">
        <p className="content__title">
          <span className="font__b">10초만에</span> 견적서 받기
        </p>
        <div className="content__body">
          <div className="left">
            <div className="column">
              <div className="columnlabel">산업 안전 보건 교육</div>
              <div className="col1__inputs">
                <div
                  onClick={() => setIsProgress(true)}
                  className={`box box-checkbox flex__center ${
                    isProgress === true ? "box-checked" : ""
                  }`}
                >
                  포함
                </div>
                <div
                  onClick={() => setIsProgress(false)}
                  className={`box box-checkbox flex__center ${
                    isProgress === false ? "box-checked" : ""
                  }`}
                >
                  미포함
                </div>
              </div>
            </div>
            <div className="column relative">
              <div className="columnlabel">교육인원</div>
              <input
                className="box"
                type="number"
                value={target}
                onChange={(e) => {
                  if (e.target.value > 10000) {
                    e.target.value = 10000;
                  }
                  setTarget(e.target.value);
                }}
              ></input>
              <span className="target">명</span>
            </div>
            <div className="column flex__row">
              <div>
                <div className="columnlabel">기업명</div>
                <input
                  className="box"
                  value={company}
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}
                ></input>
              </div>
              <div>
                <div className="columnlabel">이름/직책</div>
                <input
                  className="box"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="column">
              <div className="columnlabel">연락처</div>
              <div className="column__phone">
                <input
                  className="box"
                  value={phone1}
                  onChange={(e) => {
                    setPhone1(e.target.value);
                  }}
                />
                <span>-</span>
                <input
                  className="box"
                  value={phone2}
                  onChange={(e) => {
                    setPhone2(e.target.value);
                  }}
                />
                <span>-</span>
                <input
                  className="box"
                  value={phone3}
                  onChange={(e) => {
                    setPhone3(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="column">
              <div className="columnlabel">이메일</div>
              <input
                className="box"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></input>
            </div>
            <div className="agreement flex__center">
              <input type="checkbox" checked={agreement} />
              <div
                className={`v__checkbox ${agreement ? "checked" : ""}`}
                onClick={() => setAgreement(!agreement)}
              />
              <span>개인정보처리방침에 동의합니다&nbsp;</span>
              <a href="javascript:SITE.openModalMenu('m202201115b6f6fc1e8be9')">
                [보기]
              </a>
            </div>
          </div>
          <div className="right">
            <p className="right__title">예상견적</p>
            <p className="right__estimate font__b">
              <span>{splitPrice(estimation)}</span>원
            </p>
            <div
              className="right__box flex__center font__b"
              onClick={handleClick}
            >
              {loading ? "전송중입니다.." : <span>메일로 바로 받기 &gt;</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
